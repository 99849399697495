// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-YwgikW2{background:#224ad8;background-image:linear-gradient(180deg,#224ad8,#98afff)!important}.Iw0NPnOf{background:none}.m\\+NN08q1{--gap:0.5rem;--radius:0.25rem;display:flex;flex-direction:column;height:100vh;height:100dvh;min-height:32rem}.m\\+NN08q1 .nA7K1h5H{margin:0 var(--gap) .06rem}.nA7K1h5H{background:#fff;border-radius:var(--radius);padding:max(.84rem,min(1.25vw,1.5rem))}@media(max-width:1023.98px){.nA7K1h5H{display:none}}.hU3-0dHu{background:#fff;border-radius:var(--radius);display:flex;flex-grow:1;margin:0 var(--gap) var(--gap);overflow:hidden;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"html": "-YwgikW2",
	"body": "Iw0NPnOf",
	"container": "m+NN08q1",
	"filters": "nA7K1h5H",
	"content": "hU3-0dHu"
};
module.exports = ___CSS_LOADER_EXPORT___;
