// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PUKxF2DO{color:#fff;padding:max(.91rem,min(1.35vw,1.63rem)) max(1.13rem,min(1.67vw,2rem));position:relative}.iW5QFaJj{align-items:center;display:flex;position:relative}.iW5QFaJj>*+*{margin-left:max(1rem,min(2.5vw,3rem))}.IK9OHgh9{height:100%;left:max(-4.38rem,min(-3.65vw,-2.46rem));-o-object-fit:contain;object-fit:contain;-o-object-position:left;object-position:left;pointer-events:none;position:absolute;top:0;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:auto}@media(max-width:1229.98px){.IK9OHgh9{display:none}}.gnlPy8bB{align-items:center;background:rgba(0,0,0,.3);border-radius:.25rem;display:flex;flex-shrink:0;height:2rem;justify-content:center;transition:.1s linear;transition-property:filter;width:2rem}@media(max-width:947.98px){.gnlPy8bB{height:2.5rem;width:2.5rem}}.gnlPy8bB:active{filter:brightness(80%);transition:none}.zZCcxiv4{--size:1.13rem}.qLvIEH8S{flex-grow:1;font-family:Pragmatica-Cond,sans-serif;font-size:max(1.25rem,min(1.46vw,1.75rem));font-weight:700;justify-items:flex-end;line-height:1.2;text-align:center;text-transform:uppercase}@media(max-width:1229.98px){.qLvIEH8S{font-size:max(.94rem,min(1.46vw,1.75rem))}}@media(max-width:947.98px){.qLvIEH8S{font-size:max(1rem,min(1.46vw,1.75rem))}.qLvIEH8S>:first-child{display:none}}@media(min-width:947.98px){.qLvIEH8S>:last-child{display:none}}._16CdBTHu{border:1px solid!important;color:inherit!important;z-index:2}@media(max-width:947.98px){._16CdBTHu{height:2.5rem!important;padding:0!important;width:2.5rem!important}}.M67oEedj{--size:1.3em!important}._16CdBTHu>div{align-items:center;display:flex}._16CdBTHu>div>*+*{margin-left:.5em}@media(max-width:947.98px){._16CdBTHu>div>span{display:none}}.m8OS3k8T{display:flex;flex-shrink:0}.m8OS3k8T img{flex-shrink:0;height:2.5rem;-o-object-fit:contain;object-fit:contain;width:4.81rem}@media(max-width:1229.98px){.m8OS3k8T img{height:2rem;width:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "PUKxF2DO",
	"content": "iW5QFaJj",
	"figure": "IK9OHgh9",
	"back": "gnlPy8bB",
	"backIcon": "zZCcxiv4",
	"title": "qLvIEH8S",
	"btn": "_16CdBTHu",
	"btnIcon": "M67oEedj",
	"logo": "m8OS3k8T"
};
module.exports = ___CSS_LOADER_EXPORT___;
