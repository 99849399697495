
import Vue from "vue"
import debounce from "lodash/debounce"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default Vue.extend({
  props: {
    rawFilters: {
      type: Object as VuePropType<Record<string, any>>, required: true,
    },
  },

  data: () => ({
    open: false,
    loaded: false,
    userCoords: null as number[] | null,
    waitForCoords: false,
  }),

  computed: {
    nominantId: {
      get (): string | undefined {
        return this.$route.query.nominant as string || ""
      },
      set (nominantId?: string) {
        this.$router.push({ query: { ...this.$route.query, nominant: nominantId } })
      },
    },
  },

  watch: {
    open (open) {
      if (!open) this.nominantId = undefined
    },
    nominantId (nominantId) {
      if (nominantId) this.open = true
    },
  },

  mounted () {
    const unwatchOpen = this.$watch("open", (open) => {
      if (open) {
        this.loaded = true
        unwatchOpen()
      }
    })

    if (this.nominantId) {
      this.waitForCoords = true
      this.open = true
    } else {
      this.open = window.innerWidth >= breakpoints.md
    }

    window.addEventListener("resize", this.onResize, { passive: true })

    this.$nuxt.$on("user-coords", (coords: number[]) => {
      this.userCoords = coords || null
    })
  },

  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)

    this.$nuxt.$off("user-coords")
  },

  methods: {
    onResize: debounce(function (this: any) {
      if (window.innerWidth < breakpoints.md) this.open = false
    }, 100),

    onCoords (coords: number[] | null) {
      if (!this.waitForCoords) return
      if (coords) this.$nuxt.$emit("map-center", coords)
      this.waitForCoords = false
    },
  },
})
