
import Vue from "vue"
import type ymaps from "yandex-maps"

export default Vue.extend({
  data () {
    return {
      map: null as ymaps.Map | null,
    }
  },

  head () {
    return {
      script: [
        {
          hid: "ymaps",
          src: `https://api-maps.yandex.ru/2.1?apikey=${
            this.$config.ymapsApiKey}&lang=ru_RU`,
        },
      ],
    }
  },

  computed: {
    nominantId (): string {
      return this.$route.query.nominant as string || ""
    },
  },

  async mounted () {
    await this.init()
    this.$nuxt.$on("map-center", this.onMapCenter)
  },
  beforeDestroy () {
    this.map?.destroy()
    this.$nuxt.$off("map-center")
  },

  methods: {
    async init () {
      const { ymaps } = window
      await ymaps.ready()
      const el = this.$refs.container
      if (!el) return

      const map = this.map = new ymaps.Map(el as HTMLElement, {
        center: [55.76, 37.64],
        zoom: 7,
        controls: [],
      }, {
        minZoom: 4,
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true,
      })

      // шаблоны стилей метки
      const placemarkStyle = {
        default: {
          iconImageHref: "/icons/placemark.svg",
          iconImageSize: [32, 32],
          iconImageOffset: [-16, -16],
          iconShape: {
            type: "Circle",
            coordinates: [0, 0],
            radius: 16,
          },
        },
        active: {
          iconImageHref: "/icons/placemark-active.svg",
          iconImageSize: [32, 32],
          iconImageOffset: [-16, -16],
          iconShape: {
            type: "Circle",
            coordinates: [0, 0],
            radius: 16,
          },
        },
        // winner: {
        //   iconImageHref: "/icons/placemark-star-winner.svg",
        //   iconImageSize: [32, 32],
        //   iconImageOffset: [-16, -16],
        //   iconShape: {
        //     type: "Circle",
        //     coordinates: [0, 0],
        //     radius: 16,
        //   },
        // },
        // silver: {
        //   iconImageHref: "/icons/placemark-star-silver.svg",
        //   iconImageSize: [32, 32],
        //   iconImageOffset: [-16, -16],
        //   iconShape: {
        //     type: "Circle",
        //     coordinates: [0, 0],
        //     radius: 16,
        //   },
        // },
        // starActive: {
        //   iconImageHref: "/icons/placemark-star-active.svg",
        //   iconImageSize: [48, 48],
        //   iconImageOffset: [-24, -24],
        //   iconShape: {
        //     type: "Circle",
        //     coordinates: [0, 0],
        //     radius: 24,
        //   },
        // },
      }

      // подгружаем точки на карту
      // https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/reference/LoadingObjectManager.html#LoadingObjectManager__param-options
      // @ts-ignore
      const loadingObjectManager = new ymaps.LoadingObjectManager("/lom?bbox=%b", {
        clusterize: true,

        // опции кластеров
        clusterHideIconOnBalloonOpen: false,
        // стиль для числа в кластере
        clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
          "<div style='color: #000; font-family: Montserrat, sans-serif; font-size: 1.2rem; font-weight: 700; line-height: 2.6;'>{{ properties.geoObjects.length }}</div>",
        ),
        // иконки кластеров (малая и большая)
        clusterIcons: [
          {
            href: "/icons/cluster-sm.svg",
            size: [56, 56],
            offset: [-28, -28],
            shape: {
              type: "Circle",
              coordinates: [0, 0],
              radius: 28,
            },
          },
          {
            href: "/icons/cluster-sm.svg",
            size: [56, 56],
            offset: [-28, -28],
            shape: {
              type: "Circle",
              coordinates: [0, 0],
              radius: 28,
            },
          },
          // {
          //   href: "/icons/cluster-lg.svg",
          //   size: [72, 72],
          //   offset: [-36, -36],
          //   shape: {
          //     type: "Circle",
          //     coordinates: [0, 0],
          //     radius: 36,
          //   },
          // },
        ],
        // при размере до 10 меток в кластере — малая иконка, 10+ — большая
        clusterNumbers: [10],

        // опции меток
        geoObjectIconLayout: "default#image",
        geoObjectIconImageHref: placemarkStyle.default.iconImageHref,
        geoObjectIconImageSize: placemarkStyle.default.iconImageSize,
        geoObjectIconImageOffset: placemarkStyle.default.iconImageOffset,
        geoObjectIconShape: placemarkStyle.default.iconShape,
        geoObjectHasBalloon: false,
      })

      const setMarkStyle = (
        id: string,
        style: typeof placemarkStyle[keyof typeof placemarkStyle],
      ) =>
        loadingObjectManager.objects.setObjectOptions(id, style)

      // меняем стили меток при открытии/закрытии
      this.$watch("nominantId", (newId: string, oldId: string) => {
        // const getState = (id: string) =>
        //   loadingObjectManager.objects.getById(id)?.properties?.state || ""

        if (newId)
          setMarkStyle(newId, placemarkStyle.active)
          // const state = getState(newId)
          // if (["winner", "silver"].includes(state))
          //   setMarkStyle(newId, placemarkStyle.starActive)
          // else setMarkStyle(newId, placemarkStyle.active)

        if (oldId)
          setMarkStyle(oldId, placemarkStyle.default)
          // const state = getState(oldId)
          // if (state === "winner") setMarkStyle(oldId, placemarkStyle.winner)
          // else if (state === "silver") setMarkStyle(oldId, placemarkStyle.silver)
          // else setMarkStyle(oldId, placemarkStyle.default)
      })

      // а также при первичной загрузке/подгрузке
      loadingObjectManager.objects.events.add(["add"], (e: any) => {
        const { nominantId } = this
        const id = e.get("objectId")
        // const state = e.get("child")?.properties?.state || ""

        const setStyle = (style: Parameters<typeof setMarkStyle>[1]) =>
          setMarkStyle(id, style)

        if (id && nominantId && id === nominantId)
          // if (["winner", "silver"].includes(state))
          //   setStyle(placemarkStyle.starActive)
          setStyle(placemarkStyle.active)
        // else if (state === "winner")
        //   setStyle(placemarkStyle.winner)
        // else if (state === "silver")
        //   setStyle(placemarkStyle.silver)
      })

      // обрабатываем клик
      loadingObjectManager.objects.events.add(["click"], (e: any) => {
        const id = e.get("objectId")
        // открываем карточку с пространством
        if (id) this.$router.push({ query: { ...this.$route.query, nominant: id } })
      })

      map.geoObjects.add(loadingObjectManager)

      // центрируем на местоположение пользователя
      const location = ymaps.geolocation.get({
        // определение по ip
        provider: "yandex",
        // чтобы не тарифицировать определение адреса по координатам
        autoReverseGeocode: false,
        // центрирование
        mapStateAutoApply: Boolean(!this.nominantId),
      })

      location.then((result) => {
        map.geoObjects.add(result.geoObjects)

        // передаем координаты в список, для вычисления расстояний
        const coords = result.geoObjects.get(0).geometry
          // @ts-ignore
          ?.getCoordinates()
        if (coords) this.$nuxt.$emit("user-coords", coords)
      })
    },

    onMapCenter (coordinates: number[]) {
      this.map?.setCenter(coordinates, 19)
    },
  },
})
