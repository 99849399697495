
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  layout: "blank",

  // validate ({ store }) { return store.getters["user/isAdmin"] },

  data: () => ({
    title: "Каталог-карта мест и проектов для детей и молодежи «Выбор Первых»",
    rawFilters: {} as Record<string, any>,
  }),

  head () {
    // @ts-ignore
    const { $style } = this
    return {
      htmlAttrs: { class: $style.html },
      bodyAttrs: { class: $style.body },
      title: this.getTitle(this.title),
      link: [
        {
          rel: "preload",
          as: "font",
          crossorigin: "crossorigin",
          type: "font/ttf",
          href: "/fonts/Pragmatica-Cond/Pragmatica-Cond-700.ttf",
        },
      ],
    }
  },

  computed: {
    ...mapGetters("app", ["getTitle"]),
  },
})
