// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active,.fade-leave-active{transition:.1s linear;transition-property:transform,opacity}.fade-enter,.fade-leave-to{opacity:0;transform:translateX(2rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98"
};
module.exports = ___CSS_LOADER_EXPORT___;
