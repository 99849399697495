// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jlumn767{--width:20.19rem;background:var(--color-bg);border-radius:.25rem;box-shadow:0 .25rem 2.56rem rgba(0,0,0,.06);height:100%;max-width:89%;position:absolute;right:0;top:0;transition:.1s linear;transition-property:right;width:20.19rem;width:var(--width);z-index:1}.pc60XHGC{right:calc(var(--width)*-1 - 1px)}.pc60XHGC .bxD1RhLH{margin-left:.13rem;margin-right:-.19rem;transform:rotate(180deg)}.Iumx9ts-{height:100%}.K9GdmqpP{align-items:center;background:#fff;border:1px solid var(--color-gray-2);border-radius:.25rem;color:inherit;display:flex;justify-content:center;padding:.25rem 0;position:absolute;right:calc(100% + .5rem);top:.5rem;transition:.1s linear;transition-property:filter}.K9GdmqpP:active{filter:brightness(80%);transition:none}.bxD1RhLH{--size:1.5rem;margin-left:-.13rem;margin-right:.06rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "Jlumn767",
	"containerClosed": "pc60XHGC",
	"toggleIcon": "bxD1RhLH",
	"listWrapper": "Iumx9ts-",
	"toggle": "K9GdmqpP"
};
module.exports = ___CSS_LOADER_EXPORT___;
