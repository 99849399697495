import { render, staticRenderFns } from "./MapAside.vue?vue&type=template&id=5680e9d2"
import script from "./MapAside.vue?vue&type=script&lang=ts"
export * from "./MapAside.vue?vue&type=script&lang=ts"
import style0 from "./MapAside.vue?vue&type=style&index=0&id=5680e9d2&prod&lang=scss"
import style1 from "./MapAside.vue?vue&type=style&index=1&id=5680e9d2&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/app/components/app/AppIcon.vue').default})
